import {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {BURL} from "../../../services/base";
import axios from "axios";
import {setProfile} from "../../../slices/userSlice";
import {toast} from "react-toastify";
import Greeting from "../../../components/Greeting";
import {useFormik} from "formik";
import * as Yup from 'yup'
import CustomInput from "../../../components/CustomInput";
import CustomButton from "../../../components/CustomButton";
import ProfileGoTo from "../../../components/ProfileGoTo";

const DocumentUpload = () => {
	const {customer: {id_card, signature}} = useSelector(state => state.profile);
	const [updating, setUpdating] = useState(false);
	const [uploadOptions, setUploadOptions] = useState([]);

	const doc = useRef(null);

	const formik = useFormik({
		initialValues: {fileType: '', doc: ""},
		validationSchema: Yup.object({
			fileType: Yup.string().label('File Type').required(),
			doc: Yup.string().label('Document').required("Select a document")
		}),
		onSubmit: (values) => {
			saveFile(values);
		}
	})

	const dispatch = useDispatch();
	const saveFile = ({fileType}) => {
		setUpdating(true);
		const form = new FormData();
		form.append(fileType === 'id_card' ? 'idCard' : 'imageSignature', doc.current?.files[0]);
		// form.append('customer_id', id)
		// form.append('token', localStorage.token)
		const url = (fileType === 'id_card' ? 'upload-customer-card' : 'upload-customer-signature')
		axios.post(`${BURL}${url}`, form).then(res => {
			if (!res?.data?.success) throw new Error(res);
			axios.post(`${BURL}get-customer`).then(res => {
				if (!res?.data?.success) throw new Error(res);
				dispatch(setProfile(res.data.message));
				setUpdating(false);
				toast.success("Updated successfully")
				// successRes(successResponse);
			}).catch(err => {
				toast.error("Can't fetch updated user details")
			})
		}).catch(err => {
			setUpdating(false);
			if (err?.response?.data?.message) {
				toast.error(err?.response?.data?.message);
			} else {
				toast.error(`Can't upload, please try again`)
			}
		})
	}

	useEffect(() => {
		let opts = [];
		if (!id_card) {
			opts.push({text: "Means Of Identification", value: "id_card"})
		}
		if (!signature) {
			opts.push({text: "Signature", value: "signature"})
		}
		setUploadOptions(opts);
	}, [id_card, signature])



	return (
		<div>
			<Greeting showBack primaryBack noPre head={`Documents`} sub={'Please upload relevant document - jpg, pdf,png'} />

			<form onSubmit={formik.handleSubmit} className="flex flex-wrap gap-5 items-end">
				<CustomInput className="md:w-1/2" formik={formik} name={'fileType'} label={'Select file type'} type={'select'} options={uploadOptions} />
				<input name="doc" onChange={formik.handleChange} ref={doc} className="hidden" type="file" accept={formik.values.fileType === 'id_card' ? 'application/pdf' : 'image/*, application/pdf'} />
				<div className="flex relative flex-col h-14">
					<button type="button" onClick={() => doc.current?.click()} className="fm-group-input w-fit px-5">Browse File</button>
					<span className="text-[#8B8B8C] top-12 absolute text-xs mt-1">{doc.current?.files[0]?.name}</span>
					<small className='text-red-600 top-12 absolute text-xs mt-1'>{formik.touched && formik.errors['doc']}</small>

				</div>
				<CustomButton loading={updating} type="submit" dark className={'py-2 h-12 px-7 mb-2'}><span>Upload</span></CustomButton>
			</form>
			<div className="flex py-10 gap-5">
				<DocLayout name={'Means Of ID'} link={id_card} />
				<DocLayout name={'Signature'} link={signature} />
			</div>
			<div className='flex gap-3 py-4 w-full px-3'>
				<ProfileGoTo prev to={"/profile/others"} />
			</div>
		</div>
	)
}

const DocLayout = ({link, name}) => {
	return (
		<div className="p-5 md:w-[17rem] rounded-lg dark:shadow-none shadow-[1px_2px_20px_5px_#f3f3f3] dark:bg-dark">
			<div className="flex space-x-3 items-center text-sm font-semibold">
				<div className="bg-[#FFCCAE] h-10 w-10 justify-center items-center rounded-md flex">
					<svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path fillRule="evenodd" clipRule="evenodd" d="M1.172 1.172C-1.19209e-07 2.343 0 4.229 0 8V12C0 15.771 -1.19209e-07 17.657 1.172 18.828C2.343 20 4.229 20 8 20H10C13.771 20 15.657 20 16.828 18.828C18 17.657 18 15.771 18 12V8C18 4.229 18 2.343 16.828 1.172C15.657 -1.19209e-07 13.771 0 10 0H8C4.229 0 2.343 -1.19209e-07 1.172 1.172ZM4.25 6C4.25 5.80109 4.32902 5.61032 4.46967 5.46967C4.61032 5.32902 4.80109 5.25 5 5.25H13C13.1989 5.25 13.3897 5.32902 13.5303 5.46967C13.671 5.61032 13.75 5.80109 13.75 6C13.75 6.19891 13.671 6.38968 13.5303 6.53033C13.3897 6.67098 13.1989 6.75 13 6.75H5C4.80109 6.75 4.61032 6.67098 4.46967 6.53033C4.32902 6.38968 4.25 6.19891 4.25 6ZM4.25 10C4.25 9.80109 4.32902 9.61032 4.46967 9.46967C4.61032 9.32902 4.80109 9.25 5 9.25H13C13.1989 9.25 13.3897 9.32902 13.5303 9.46967C13.671 9.61032 13.75 9.80109 13.75 10C13.75 10.1989 13.671 10.3897 13.5303 10.5303C13.3897 10.671 13.1989 10.75 13 10.75H5C4.80109 10.75 4.61032 10.671 4.46967 10.5303C4.32902 10.3897 4.25 10.1989 4.25 10ZM5 13.25C4.80109 13.25 4.61032 13.329 4.46967 13.4697C4.32902 13.6103 4.25 13.8011 4.25 14C4.25 14.1989 4.32902 14.3897 4.46967 14.5303C4.61032 14.671 4.80109 14.75 5 14.75H10C10.1989 14.75 10.3897 14.671 10.5303 14.5303C10.671 14.3897 10.75 14.1989 10.75 14C10.75 13.8011 10.671 13.6103 10.5303 13.4697C10.3897 13.329 10.1989 13.25 10 13.25H5Z" fill="#A90F41" />
					</svg>
				</div>
				<span>{name}</span>
			</div>
			<div className="flex justify-end">
				{!link ? <span className="text-[#ABA5A5] text-xs">No file</span> : <span className="text-[#ABA5A5] text-xs">Uploaded</span>}
			</div>
		</div>
	)
}
export default DocumentUpload