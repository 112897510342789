import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@mui/material";
import axios from "axios";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import CurrencyFormat from "react-currency-format";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { BURL, formatNum } from "../../services/base";
import { setInvestments } from "../../slices/userSlice";
import CustomButton from "../CustomButton";
import BankNoteIcon from "../Icons/BankNoteIcon";
import RightArrIcon from "../Icons/RightArrIcon";

const TopUpInvestment = ({ open, setOpen, investment: inv }) => {
    const [inProgress, setInProgress] = useState(false);
    const { cash_balance } = useSelector((state) => state.profile);
    const [err, setErr] = useState(null);

    const [state, setState] = useState({
        tenure: inv.rem_days,
        rem_tenure: inv.rem_days,
        principal: inv.principal,
        new_principal: inv.principal,
        new_maturity_interest: inv.maturity_interest,
        topup_date: new Date(),
        payback_amount: 0,
        is_new_topup: !inv.topup_date,
        topup_amount: inv.topup_amount || 0,
    });

    const wht_status = Number(inv.wht_status);
    const market = Number(inv.money_market);
    const primary_principal = parseFloat(inv.principal);

    const isLeapYear = (year) => {
        return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
    };

    const updateData = () => {
        if (inv.maturity_date && state.topup_amount !== undefined) {
            calculateTenure();
        }

        if (!state.tenure) {
            setState((prevState) => ({ ...prevState, tenure: inv.rem_days }));
        }

        let today = new Date();
        today.setHours(0, 0, 0, 0);
        let topupDate = new Date(state.topup_date);
        topupDate.setHours(0, 0, 0, 0);

        if (topupDate <= today) {
            let newPrincipal = primary_principal;
            let diffInDays = getDifferenceInDays(
                inv.topup_date || inv.active_date,
                state.topup_date
            );

            let activeYear = moment(inv.topup_date || inv.active_date).year();
            const topupYear = moment(state.topup_date).year();
            let t1 = 0,
                t2 = 0;

            if (activeYear !== topupYear) {
                let activeYearEnd = moment(
                    inv.topup_date || inv.active_date
                ).endOf("year");

                t1 = Math.max(
                    0,
                    activeYearEnd.diff(
                        moment(inv.topup_date || inv.active_date),
                        "days"
                    )
                );

                t2 =
                    Math.max(
                        0,
                        moment(state.topup_date).diff(
                            moment(inv.topup_date || inv.active_date)
                                .add(1, "year")
                                .startOf("year"),
                            "days"
                        )
                    ) + 1;

                const activeYearLeap = isLeapYear(activeYear);
                const topupYearLeap = isLeapYear(topupYear);
                const yearNum1 = activeYearLeap ? 36600 : 36500;
                const yearNum2 = topupYearLeap ? 36600 : 36500;

                let acrInterest =
                    ((primary_principal * inv.rate) / yearNum1) * t1 +
                    ((primary_principal * inv.rate) / yearNum2) * t2;

                let whtInterest =
                    ((primary_principal * inv.rate) / (yearNum1 * 10)) * t1 +
                    ((primary_principal * inv.rate) / (yearNum2 * 10)) * t2;

                let accInterest =
                    inv.wht_status === 1
                        ? acrInterest - whtInterest
                        : acrInterest;

                newPrincipal =
                    market === 0
                        ? primary_principal + accInterest + state.topup_amount
                        : primary_principal + state.topup_amount;

                if (market === 0) {
                    t1 = 0;
                    t2 = moment(inv.maturity_date).diff(topupDate, "days");
                }

                let part1 =
                    (newPrincipal * inv.rate * t1) / yearNum1 +
                    (newPrincipal * inv.rate * t2) / yearNum2;
                let part2 =
                    ((newPrincipal * inv.rate * t1) / yearNum1 +
                        (newPrincipal * inv.rate * t2) / yearNum2) *
                    0.1;

                let newMaturityInterest =
                    inv.wht_status === 1 ? part1 - part2 : part1;

                let paybackAmount =
                    newPrincipal - primary_principal - state.topup_amount;

                setState((prevState) => ({
                    ...prevState,
                    new_principal: newPrincipal,
                    new_maturity_interest: newMaturityInterest,
                    payback_amount: paybackAmount,
                }));
            } else {
                // Handle the case when activeYear === topupYear (missing else block added here)
                let january1_next_year = moment(state.topup_date)
                    .add(1, "year")
                    .startOf("year");

                if (market == 0) {
                    t1 = Math.min(
                        Math.ceil(
                            moment
                                .duration(
                                    moment(january1_next_year)
                                        .subtract(1, "day")
                                        .diff(topupDate)
                                )
                                .asDays()
                        ),
                        state.tenure
                    );
                    t2 = Math.max(state.tenure - t1, 0);

                    const yearNum = isLeapYear(activeYear) ? 36600 : 36500;

                    let acrInterest =
                        ((primary_principal * inv.rate) / yearNum) * diffInDays;
                    let whtInterest =
                        ((primary_principal * inv.rate) / yearNum) *
                        diffInDays *
                        0.1;

                    let accInterest =
                        inv.wht_status === 1
                            ? acrInterest - whtInterest
                            : acrInterest;

                    newPrincipal =
                        primary_principal + accInterest + state.topup_amount;

                    let part1 =
                        (newPrincipal * inv.rate * t1) / yearNum +
                        (newPrincipal * inv.rate * t2) / yearNum;
                    let part2 =
                        ((newPrincipal * inv.rate * t1) / yearNum) * 0.1 +
                        ((newPrincipal * inv.rate * t2) / yearNum) * 0.1;

                    let newMaturityInterest =
                        inv.wht_status === 1 ? part1 - part2 : part1;

                    let paybackAmount =
                        newPrincipal - primary_principal - state.topup_amount;

                    setState((prevState) => ({
                        ...prevState,
                        new_principal: newPrincipal,
                        new_maturity_interest: newMaturityInterest,
                        payback_amount: paybackAmount,
                    }));
                } else if (market == 1) {
                    t2 = 0;

                    t1 = getDifferenceInDays(
                        inv.topup_date || inv.active_date,
                        topupDate
                    );

                    // Check if active and topup years are leap years
                    const activeYearLeap = isLeapYear(activeYear);
                    const maturityYearLeap = isLeapYear(
                        parseInt(moment(inv.maturity_date).format("YYYY"))
                    );
                    const topupYearLeap = isLeapYear(topupYear);

                    // Define the number of days in a year based on leap years
                    const yearNum1 = activeYearLeap ? 36600 : 36500;
                    const yearNum2 = maturityYearLeap ? 36600 : 36500;

                    // Calculate accrued interest
                    let acrInterest =
                        ((primary_principal * inv.rate) / yearNum1) *
                        diffInDays;

                    // Calculate withholding tax interest
                    let whtInterest =
                        ((primary_principal * inv.rate) / yearNum1) *
                        diffInDays *
                        0.1;

                    // Adjust accrued interest based on withholding tax status
                    let accInterest =
                        inv.wht_status === 1
                            ? acrInterest - whtInterest
                            : acrInterest;

                    // Update new principal based on accrued interest and top-up amount
                    let newPrincipal = primary_principal + state.topup_amount;

                    // Calculate new maturity interest based on withholding tax status
                    let part1 =
                        (primary_principal * inv.rate * t1) / yearNum1 +
                        (primary_principal * inv.rate * t2) / yearNum2;
                    let part2 =
                        ((primary_principal * inv.rate * t1) / yearNum1) * 0.1 +
                        ((primary_principal * inv.rate * t2) / yearNum2) * 0.1;

                    let newMaturityInterest =
                        inv.wht_status === 1 ? part1 - part2 : part1;

                    // Update state
                    setState((prevState) => ({
                        ...prevState,
                        new_principal: newPrincipal,
                        new_maturity_interest: newMaturityInterest,
                    }));
                }
            }
        }
    };

    const calculateTenure = () => {
        if (!state.topup_date) return;

        const activeDate = moment(state.topup_date, "DD/MM/YYYY");
        const maturityDate = moment(inv.maturity_date, "YYYY-MM-DD").endOf(
            "day"
        );

        setState((prevState) => ({
            ...prevState,
            tenure: Math.abs(maturityDate.diff(activeDate, "days")),
        }));
    };

    const getDifferenceInDays = (date1, date2) => {
        if (!date1 || !date2) return 0;

        const startDate = new Date(date1);
        const endDate = new Date(date2);
        startDate.setHours(0, 0, 0, 0);

        return Math.floor((endDate - startDate) / (1000 * 60 * 60 * 24));
    };

    useEffect(() => {
        updateData();
    }, [state.topup_date, state.topup_amount]);

    useEffect(() => {
        if (open) {
            setState((state) => ({ ...state, topup_amount: 0 }));
        }
    }, [open]);

    const successRes = () => {
        setOpen(false);
        Swal.fire({
            title: "Top Up Successful",
            text: "Thank you for Investing with us",
            icon: "success",
            // didClose: () => setOpen(false),
            confirmButtonText: "Continue",
        });
    };
    const errorRes = (res) => {
        Swal.fire({
            title: "Can't TopUp Investment",
            text: res,
            icon: "error",
            // didClose: () => setOpen(false),
            confirmButtonText: "Okay",
        });
    };
    const dispatch = useDispatch();
    const onSubmit = (e) => {
        e.preventDefault();
        if (err || !state.topup_amount) {
            return;
        }
        const payload = {
            id: inv.id,
            customer_id: inv.customer_id,
            new_principal: state.new_principal,
            tenure: state.tenure,
            payback_amount: state.payback_amount,
            new_maturity_interest: state.new_maturity_interest,
            topup_amount: state.topup_amount,
            topup_date: new Date(),
        };

        setInProgress(true);
        axios
            .post(`${BURL}top-up-investment`, payload)
            .then((res) => {
                if (!res?.data?.success) throw new Error(res);
                setInProgress(false);
                axios
                    .get(`${BURL}get-investments`)
                    .then((res) => {
                        if (!res?.data?.success) throw new Error(res);
                        dispatch(setInvestments(res?.data?.message));
                        setTimeout(() => {
                            window.location.reload();
                        }, 1000);
                    })
                    .catch((err) => {
                        toast.error("Can't update investment list");
                    });
                successRes();
            })
            .catch((err) => {
                setInProgress(false);
                if (err?.response?.data) {
                    errorRes(err?.response?.data?.message);
                } else {
                    errorRes("An error occured, please try again.");
                }
            });
    };

    const handleChange = (e) => {
        if (!e.value) {
            setErr("Amount is required");
            return;
        } else if (Number(e.value) < 1) {
            setErr(`Amount can't be less than 1`);
            return;
        } else if (
            inv.currency === "NGN" &&
            parseFloat(parseFloat(e.value).toFixed(2)) >
                parseFloat(
                    parseFloat(cash_balance?.customerNairaBalance || 0).toFixed(
                        2
                    )
                )
        ) {
            setErr(`Insufficient balance`);
            return;
			
        } else if (
            inv.currency === "USD" &&
            Number(e.value) > cash_balance?.customerDollarBalance
        ) {
            setErr(`Insufficient dollar balance`);
            return;
        } else {
            setErr(null);
        }

        setState((state) => ({ ...state, topup_amount: e.floatValue }));
    };

    return (
        <>
            <Dialog
                fullWidth
                maxWidth={"sm"}
                PaperProps={{
                    sx: { maxWidth: 500, borderRadius: 4 },
                    className: "bg-[#F7F7F7] dark:bg-trov-dark dark:text-white",
                }}
                open={open}
                onClose={() => {
                    setOpen(false);
                    window.location.reload();
                }}
            >
                <DialogTitle className="">
                    <div className="dialog-title text-xl md:mt-2 mb-0">
                        Topup Investment - {inv.ref_id}
                    </div>
                    <h3 className="dialog-sub text-sm mt-0">
                        {inv.product.name}
                    </h3>
                    <h5 className="dialog-sub text-sm mt-0">
                        Cash Balance = {`₦${formatNum(inv.cash_balance)}`}
                    </h5>
                    <h5 className="dialog-sub text-sm mt-0">
                        Current Principal = {`₦${formatNum(inv.principal)}`}
                    </h5>
                    {inv.money_market === 0 && (
                        <h5 className="dialog-sub text-sm mt-0">
                            Current Maturity Interest ={" "}
                            {`₦${formatNum(inv.maturity_interest)}`}
                        </h5>
                    )}
                </DialogTitle>
                <form onSubmit={onSubmit}>
                    <DialogContent>
                        <DialogContentText className="">
                            <span className="w-full block dark:text-white max-w-[26rem] mx-auto">
                                <label className="ml-2 text-xs font-bold">
                                    TopUp Amount
                                </label>
                                <span className="flex items-center relative flex-wrap">
                                    <span className="absolute left-3">
                                        <BankNoteIcon />
                                    </span>
                                    <CurrencyFormat
                                        autoFocus
                                        thousandSeparator={true}
                                        prefix={
                                            inv.product.currency === "NGN"
                                                ? "₦ "
                                                : "$ "
                                        }
                                        onValueChange={handleChange}
                                        placeholder="Enter Amount"
                                        className={`w-full max-w-md border border-black dark:border-white rounded-full h-9 pr-4 pl-9 outline-none bg-transparent focus:border-primary transition text-sm ${
                                            err &&
                                            "border-red-500 dark:border-red-500"
                                        }`}
                                    />
                                </span>
                                {err && (
                                    <small className="absolute block ml-2 text-red-500">
                                        {err}
                                    </small>
                                )}
                            </span>
                        </DialogContentText>
                    </DialogContent>
                    {/* <div className="bg-light break-words dark:bg-dark text-sm py-4 px-3">
                        <div className="max-w-[26rem] mx-auto grid grid-cols-2 gap-2">
                            <div className="">New Principal:</div>
                            <b>{`${
                                inv.product.currency === "NGN" ? "₦" : "$"
                            }${formatNum(state.new_principal)}`}</b>
                            {!inv.product.money_market && (
                                <>
                                    <div className="">
                                        New Tenure (From Today):
                                    </div>
                                    <b>{`${inv.rem_days} Days`}</b>
                                    <div className="">
                                        New Maturity Interest:
                                    </div>
                                    <b>{`${
                                        inv.product.currency === "NGN"
                                            ? "₦"
                                            : "$"
                                    }${formatNum(
                                        state.new_maturity_interest
                                    )}`}</b>
                                </>
                            )}
                        </div>
                    </div> */}
                    <div className="bg-light break-words dark:bg-dark text-sm py-4 px-3">
                        <div className="max-w-[26rem] mx-auto grid grid-cols-2 gap-2">
                            <div className="">New Principal:</div>
                            <b>{`${
                                inv.product.currency === "NGN" ? "₦" : "$"
                            }${formatNum(state.new_principal)}`}</b>
                            {inv.money_market === 0 && (
                                <>
                                    <div className="">
                                        New Tenure (From Today):
                                    </div>
                                    <b>{`${inv.rem_days} Days`}</b>

                                    <div className="">
                                        New Maturity Interest:
                                    </div>
                                    <b>{`${
                                        inv.product.currency === "NGN"
                                            ? "₦"
                                            : "$"
                                    }${formatNum(
                                        state.new_maturity_interest
                                    )}`}</b>
                                </>
                            )}
                        </div>
                    </div>
                    <DialogActions sx={{ justifyContent: "center" }}>
                        <CustomButton
                            dark
                            loading={inProgress}
                            className="w-full max-w-[26rem] my-4 justify-center mx-2"
                            type="submit"
                        >
                            <span>Continue</span>
                            {!inProgress && (
                                <RightArrIcon className="fill-white" />
                            )}
                        </CustomButton>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
};

export default TopUpInvestment;
