import React from 'react'
import {Text, View, StyleSheet, Page, Document, Image, PDFDownloadLink, Font} from "@react-pdf/renderer";
import logo from '../assets/logo.png';
import signature1 from '../assets/opmgsig.png';
import signature2 from '../assets/mgdirectorsig.png';
import {useSelector} from 'react-redux';
import NotoSans from '../assets/NotoSans.ttf'
import Gothic from '../assets/GOTHIC.TTF'
import GothicB from '../assets/GOTHICB.TTF'
Font.register({family: 'Noto Sans', src: NotoSans});
Font.register({family: 'Gothic', src: Gothic});
Font.register({family: 'GothicB', src: GothicB});
const styles = StyleSheet.create({
	body: {
		paddingVertical: 40,
		paddingHorizontal: 50,
		fontSize: 10,
		fontFamily: 'Gothic',
	},
	table: {
		display: "table",
		width: '100%',
		borderTop: '0.8 solid #000',
	},
	tableRow: {
		border: '0.8 solid #000',
		borderTopWidth: 0,
		margin: "auto",
		flexDirection: "row",
		display: 'flex'
	},
	tableCol: {
	},
	tableCell: {
		margin: 3,
		fontWeight: 'bold'
	},
	head: {
		display: 'flex',
		flexDirection: 'column',
		gap: 15
	},
	footer: {
		border: '0.8 dotted #000',
		borderRightWidth: 0,
		borderLeftWidth: 0,
		paddingVertical: 10,
		gap: 2,
		fontSize: 8,
		display: 'flex',
		flexDirection: 'column',
		marginVertical: 10
	},
	sigCont: {
		display: 'flex',
		flexDirection: 'column',
		gap: 3,
		alignItems: 'center',
		fontSize: 8
	}
});

const MFMStmt = ({details, product}) => {
	const {data, customer
	} = useSelector(state => state.profile);
	const isCORP = (customer?.label === "CORP");
	return (
		<>
			<PDFDownloadLink document={
				<Document>
					<Page>
						<View style={styles.body}>
							<View style={styles.head}>
								<Image src={logo} style={{width: 100}} />
								<Text>Plot 1601, Adeola Hopewell Street, Victoria Island, Lagos.</Text>
								<Text style={{fontFamily: 'GothicB', marginVertical: 3}}>{new Date().toDateString()}</Text>
								<View style={{display: 'flex', flexDirection: 'column', fontWeight: 'bold', textTransform: 'capitalize'}}>
									<Text style={{fontFamily: 'GothicB', }}>
										{(isCORP ? data.business_name : `${data?.firstname} ${data?.lastname}`)?.toUpperCase()}
									</Text>
									<Text>{data?.address},</Text>
									<Text>{data?.city}, {data?.state}.</Text>
									<Text>
										{(isCORP ? data.business_name : `Dear ${data?.firstname}`)},
									</Text>
								</View>
								<View style={{display: 'flex', flexDirection: 'column', gap: 8}}>
									<Text style={{fontFamily: 'GothicB'}}> INVESTMENT STATEMENT</Text>
									{details[0] && <Text>Transactions from {new Date(details[0].date).toDateString()} to {new Date(details[Number(details.length) - 1].date).toDateString()}</Text>}
								</View>
								<View style={styles.table}>
									<View style={styles.tableRow}>
										{
											["Date", "Principal", "Interest Earned", "Cumulative Interest"].map((each, i) => (
												<View style={{...styles.tableCol, width: '25%', borderRight: i >= 3 ? '' : '0.8 solid #000', fontFamily: 'GothicB', textTransform: 'uppercase'}}>
													<Text style={styles.tableCell}>{each}</Text>
												</View>
											))
										}
									</View>
									{
										details.filter(each => each).map(each => (
											<View style={styles.tableRow}>
												<View style={{...styles.tableCol, width: '25%', fontFamily: 'GothicB'}}>
													<Text style={styles.tableCell}>{new Date(each.date).toDateString()}</Text>
												</View>
												<View style={{...styles.tableCol, width: '25%', fontFamily: 'GothicB', borderLeft: '0.8 solid #000'}}>
													<Text style={{...styles.tableCell, textTransform: 'capitalize'}}>&nbsp;&nbsp;&nbsp;
														<Text style={{fontFamily: 'Noto Sans'}} >{each.pdfCurrency}</Text>
														{each.principal}
													</Text>
												</View>
												<View style={{...styles.tableCol, width: '25%', fontFamily: 'GothicB', borderLeft: '0.8 solid #000'}}>
													<Text style={{...styles.tableCell, textTransform: 'capitalize'}}>&nbsp;&nbsp;&nbsp;
														<Text style={{fontFamily: 'Noto Sans'}} >{each.pdfCurrency}</Text>
														{each.earned}
													</Text>
												</View>
												<View style={{...styles.tableCol, width: '25%', fontFamily: 'GothicB', borderLeft: '0.8 solid #000'}}>
													<Text style={{...styles.tableCell, textTransform: 'capitalize'}}>&nbsp;&nbsp;&nbsp;
														<Text style={{fontFamily: 'Noto Sans'}} >{each.pdfCurrency}</Text>
														{each.interest}
													</Text>
												</View>
											</View>
										))
									}
								</View>
								{/* <View style={{display: 'flex', flexDirection: 'column', gap: 8}}>
								<Text>Yours faithfully,</Text>
								<Text style={{fontWeight: 'bold'}}>TRO-VEST ASSET MANAGEMENT</Text>
							</View> */}
								<View style={{display: 'flex', gap: 8, flexDirection: 'row', justifyContent: 'space-between', marginTop: 10}}>
									<View style={styles.sigCont}>
										<Image src={signature1} style={{height: 40, width: 80, paddingBottom: 5, borderBottom: '1 solid black'}} />
										<Text>Funds Manager</Text>
									</View>
									<View style={styles.sigCont}>
										<Image src={signature2} style={{height: 40, width: 80, paddingBottom: 5, borderBottom: '1 solid black'}} />
										<Text>Managing Director</Text>
									</View>
								</View>
								<View style={styles.footer}>
									<Text style={{textDecoration: 'underline'}}>PLEASE NOTE</Text>
									<Text>* Your payment of the funds for Investment and our issue of this Certificate indicates our mutual agreement with the contract terms.</Text>
									<Text> * Where the Investment booking details does not agree with your records, kindly contact our customer care department at <Text style={{color: 'blue'}}>customercare@tro-vestassetmngt.com</Text> or your Relationship Manager immediately for regularization.</Text>
									<Text> * Kindly confirm to us your rollover instructions on or before maturity if there is any change. </Text>
									<Text>* Upon pre - liquidation of this investment, interest shall be calculated at a penal rate as applicable. </Text>
									<Text>* Tro-vest Asset Management also reserves the right to adjust the rates upwards or downwards in line with market realities. * </Text>
									<Text> * Pre - liquidation of investment will be treated within 48 hours from the date the liquidation request is received. *</Text>
								</View>
							</View>
						</View>
					</Page>
				</Document>
			} fileName={`${product.name} certificate`} className='text-blue-400 text-xs'>
				Download {product.money_market ? 'Statement' : 'Certificate'}
			</PDFDownloadLink>
		</>
	)
}

export default MFMStmt