import {
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
} from "@mui/material";
import React from "react";
import {useLocation, useNavigate} from "react-router";
import {Link} from "react-router-dom";
import CashIcon from "./Icons/CashIcon";
import DashboardIcon from "./Icons/DashboardIcon";
import InvestmentIcon from "./Icons/InvestmentIcon";
import LayersIcon from "./Icons/LayersIcon";
// import UserIcon from './Icons/UserIcon';
import {useSelector} from "react-redux";
import LogoutIcon from "./Icons/LogoutIcon";

const SideNav = ({handleDrawerToggle}) => {
    const links = [
        {text: "Dashboard", link: "/dashboard", icon: <DashboardIcon />},
        {text: "Investment", link: "/investment", icon: <InvestmentIcon />},
        {text: "Wallet", link: "/wallet", icon: <CashIcon />},
        {text: "Transactions", link: "/transactions", icon: <LayersIcon />},
        // {text: "Profile", link: "/profile", icon: <UserIcon />},
    ];
    const {company: {logo}} = useSelector(state => state.profile);


    const navigate = useNavigate();
    const location = useLocation();

    const logout = () => {
        localStorage.removeItem("token");
        navigate("/login");
    };
    return (
        <>
            <Link to="/">
                <img
                    className="w-36 ml-8 my-8 rm-drag [filter:invert(100%)_brightness(1000%)]"
                    alt="logo"
                    src={logo}
                />
            </Link>
            <List sx={{paddingTop: 5}} className="h-full relative">
                {links.map((link, index) => (
                    <ListItem key={index} sx={{paddingX: 3, paddingY: 0.3}}>
                        <Link
                            to={link.link}
                            className={`w-full rounded-full`}
                            onClick={handleDrawerToggle}
                        >
                            <ListItemButton
                                sx={{
                                    paddingLeft: 3,
                                    borderRadius: 1000,
                                    ":hover": {
                                        backgroundColor: "transparent",
                                    },
                                }}
                            >
                                <ListItemIcon sx={{minWidth: 40}}>
                                    <span
                                        className={`${location.pathname.includes(
                                            link.link
                                        )
                                                ? " text-primary"
                                                : "text-white"
                                            }`}
                                    >
                                        {link.icon}
                                    </span>
                                </ListItemIcon>
                                <ListItemText>
                                    <span
                                        className={`text-sm ${location.pathname.includes(
                                            link.link
                                        )
                                                ? "font-bold text-primary"
                                                : "text-white"
                                            }`}
                                    >
                                        {link.text}
                                    </span>
                                </ListItemText>
                            </ListItemButton>
                        </Link>
                    </ListItem>
                ))}
                <ListItem
                    disablePadding
                    onClick={logout}
                    className="bottom-10"
                    sx={{position: "absolute"}}
                >
                    <ListItemButton sx={{paddingLeft: 5}}>
                        <ListItemIcon sx={{minWidth: 40}}>
                            <span className="text-white">
                                <LogoutIcon />
                            </span>
                        </ListItemIcon>
                        <ListItemText>
                            <span className="text-sm text-white">Logout</span>
                        </ListItemText>
                    </ListItemButton>
                </ListItem>
            </List>
        </>
    );
};

export default SideNav;
