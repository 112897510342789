import React from "react";
import {Link, Outlet, useLocation} from "react-router-dom";
import "../App.css";
// import AuthIllustration from '../components/Illustrations/AuthIllustration';
import {useSelector} from "react-redux";
import AuthThemeControl from "../components/AuthThemeControl";

const Register = () => {
    const {pathname} = useLocation();
    const {company: {logo}} = useSelector(state => state.profile);

    return (
        <div className="relative min-h-screen bg-fixed flex bg-blend-color-burn bg-[url('./assets/authPattern.svg')] dark:bg-[url('./assets/authPattern2.svg')] bg-cover">
            <div className="max-w-[65rem] px-10 lg:px-5 mx-auto flex flex-col min-h-full w-full relative">
                <div className="flex justify-between flex-wrap py-10 gap-5 items-center">
                    <img
                        alt="logo"
                        src={logo}
                        className="w-36 dark:[filter:invert(100%)_brightness(1000%)]"
                    />
                    <div className="flex md:items-center flex-col md:flex-row-reverse gap-3 md:gap-10">
                        <p className="text-sm text-[#8B8B8C]">
                            <Link to="/login">
                                Already have an account?{" "}
                                <span className="text-primary">Sign In</span>
                            </Link>
                        </p>
                        {/* <div className='bg-white rounded-full p-1 flex w-fit text-sm'>
							<Link to="/register/individual" className={`transition-all duration-300 rounded-full px-4 py-1 ${pathname.includes('individual') ? 'bg-primary text-white' : 'bg-white text-black'}`}>
								Individual
							</Link>
							<Link to="/register/corporate" className={`transition-all duration-300 rounded-full px-4 py-1 ${pathname.includes('corporate') ? 'bg-primary text-white' : 'bg-white text-black'}`}>
								Corporate
							</Link>
						</div> */}
                    </div>
                </div>
                <AuthThemeControl />
                <div className="w-full pb-10 flex justify-between my-auto">
                    <div className="w-full md:w-1/2 max-w-[23rem]">
                        <h3 className="text-2xl font-semibold">
                            Start Your{" "}
                            {pathname.includes("corporate") && "Corporate"}{" "}
                            Investment Journey Today
                        </h3>
                        <p className="text-gray-500 text-xs mb-5">
                            Sign up let us simplify your financial journey.
                        </p>
                        <Outlet />
                    </div>
                    <div className="hidden md:flex w-1/2 xl:w-3/5 h-full pt-10">
                        {/* <AuthIllustration /> */}
                        <img
                            src={
                                "https://tro-vestassetmngt.com/wp-content/uploads/2023/05/On-boarding-light-mode.gif"
                            }
                            alt="Wallet Illustration"
                            className="rm-drag dark:hidden h-[30rem] ml-auto"
                        />
                        <img
                            src={
                                "https://tro-vestassetmngt.com/wp-content/uploads/2023/05/On-boarding-dark-mode.gif"
                            }
                            alt="Wallet Illustration"
                            className="rm-drag h-[30rem] hidden dark:block ml-auto"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Register;
