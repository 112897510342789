import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@mui/material";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { BURL } from "../../services/base";
import CustomButton from "../CustomButton";
import RightArrIcon from "../Icons/RightArrIcon";
import { useDispatch, useSelector } from "react-redux";
import { setInvestments } from "../../slices/userSlice";
import CurrencyFormat from "react-currency-format";
import BankNoteIcon from "../Icons/BankNoteIcon";
import { formatNum } from "../../services/base";
import moment from "moment";

const PartLiquidation = ({ open, setOpen, investment: inv }) => {
    const [inProgress, setInProgress] = useState(false);
    console.log(inv);

    const [state, setState] = useState({
        ...inv,
        tenure: inv.rem_days,
        new_principal: 0,
        new_maturity_interest: inv.maturity_interest,
        downsize_amount: 0,
        termination_interest_amount: 0,
        amount_after_termination: 0,
        percent_for_calc: 0,
        amount_calc: 0,
        interest_charge: inv.interest_charge || 0,
        loader: false,
        market: 0,
        rate: inv.rate,
    });

    useEffect(() => {
        updateData();
    }, [state.downsize_amount]);

    const handleChange = (values) => {
        setState((prev) => ({
            ...prev,
            downsize_amount: parseFloat(values.value) || 0,
        }));
        updateData();
    };

    const updateData = () => {
        let primary_principal = inv.principal;
        let market = inv.money_market;
        console.log(market);
        let keep_wht_status = inv.wht_status;

        if (state.maturity_date && state.downsize_amount !== undefined) {
            calculateTenure();
        }

        let tenure = state.tenure || state.rem_days;
        let new_principal = 0;
        let new_maturity_interest = 0;

        let principal =
            parseFloat(primary_principal) - parseFloat(state.downsize_amount);
        let keep_principal = principal;

        let percent_for_calc = (
            (parseFloat(state.downsize_amount) * 100) /
            state.principal
        ).toFixed(2);

        let amount_calc = (
            (percent_for_calc / 100) *
            state.date_interest
        ).toFixed(2);

        new_principal = principal;

        if (market === 0) {
            console.log("vvvv");
            let ppenalty = isNaN(state.interest_charge)
                ? 0
                : state.interest_charge;
            let termination_interest_amount = (amount_calc * ppenalty) / 100;

            new_principal += state.date_interest - amount_calc;

            let amount_after_termination =
                amount_calc - termination_interest_amount;

            let part1 = (new_principal * state.rate * tenure) / 36500;
            let part2 = part1 * 0.1;

            new_maturity_interest =
                state.wht_status === 1 ? part1 - part2 : part1;

            if (
                state.downsize_amount === 0 &&
                keep_wht_status === state.wht_status
            ) {
                new_maturity_interest = state.maturity_interest;
            }

            setState((prev) => ({
                ...prev,
                tenure,
                new_principal,
                new_maturity_interest,
                termination_interest_amount,
                amount_after_termination,
                percent_for_calc,
                amount_calc,
            }));
        } else {
            setState((prev) => ({
                ...prev,
                new_principal,
            }));
        }
    };

    const calculateTenure = () => {
        const today = moment();
        const maturityDate = moment(state.maturity_date, "DD/MM/YYYY").endOf(
            "day"
        );

        setState((prev) => ({
            ...prev,
            tenure: Math.abs(maturityDate.diff(today, "days")),
        }));
    };

    const successRes = () => {
        setOpen(false);
        Swal.fire({
            title: "Part Liquidation Successful",
            text: "Thank you for Investing with us",
            icon: "success",
            // didClose: () => setOpen(false),
            confirmButtonText: "Continue",
        });
    };

    // trigger if any error occured
    const errorRes = (res) => {
        Swal.fire({
            title: "Can't Part Liquidate Investment",
            text: res,
            icon: "error",
            // didClose: () => setOpen(false),
            confirmButtonText: "Okay",
        });
    };
    const dispatch = useDispatch();

    const onSubmit = (e) => {
        e.preventDefault();

        let residue_amount = inv.date_interest - state.amount_calc;
        let payload = {
            id: state.id,
            customer_id: inv.customer_id,
            new_principal: state.new_principal,
            tenure: state.tenure ?? 0,
            wht_status: inv.wht_status,
            downsize_amount: parseFloat(state.downsize_amount),
            termination_interest_amount: parseFloat(
                state.termination_interest_amount
            ),
            amount_after_termination: parseFloat(
                state.amount_after_termination
            ),
            residue_amount,
            new_maturity_interest: state.new_maturity_interest ?? 0,
            active_date: new Date(),
        };
        setInProgress(true);
        axios
            .post(`${BURL}down-size-investment`, payload)
            .then((res) => {
                if (!res?.data?.success) throw new Error(res);
                setInProgress(false);

                axios
                    .get(`${BURL}get-investments`)
                    .then((res) => {
                        if (!res?.data?.success) throw new Error(res);
                        setState((prev) => ({
                            ...prev,
                            downsize_amount: 0,
                        }));
                        dispatch(setInvestments(res?.data?.message));
                        setTimeout(() => {
                            window.location.reload();
                        }, 1000);
                    })
                    .catch((err) => {
                        toast.error("Can't update investment list");
                    });
                successRes();
            })
            .catch((err) => {
                setInProgress(false);
                if (err?.response?.data) {
                    errorRes(err?.response?.data?.message);
                } else {
                    errorRes("An error occured, please try again.");
                }
            });
    };

    return (
        <Dialog
            fullWidth
            maxWidth="sm"
            open={open}
            PaperProps={{
                sx: { maxWidth: 500, borderRadius: 4 },
                className: "bg-[#F7F7F7] dark:bg-trov-dark dark:text-white",
            }}
            onClose={() => {
                setOpen(false);
                window.location.reload();
            }}
        >
            <DialogTitle className="">
                <div className="dialog-title text-xl md:mt-2 mb-0">
                    Part Liquidate Investment - {inv.ref_id}
                </div>
                <h3 className="dialog-sub text-sm mt-0">{inv.product.name}</h3>
                <h5 className="dialog-sub text-sm mt-0">
                    Cash Balance = {`₦${formatNum(inv.cash_balance)}`}
                </h5>
                <h5 className="dialog-sub text-sm mt-0">
                    Current Principal = {`₦${formatNum(inv.principal)}`}
                </h5>
                {inv.money_market === 0 && (
                    <h5 className="dialog-sub text-sm mt-0">
                        Current Maturity Interest ={" "}
                        {`₦${formatNum(inv.maturity_interest)}`}
                    </h5>
                )}
            </DialogTitle>
            <form onSubmit={onSubmit}>
                <DialogContent>
                    <CurrencyFormat
                        value={state.downsize_amount}
                        autoFocus
                        thousandSeparator={true}
                        prefix={inv.currency === "NGN" ? "₦ " : "$ "}
                        onValueChange={handleChange}
                        placeholder="Enter Amount"
                        className="w-full max-w-md border border-black rounded-full h-9 pr-4 pl-9 outline-none bg-transparent focus:border-primary transition text-sm"
                    />
                    <div className="p-6  max-w-md mx-auto">
                        <div className="space-y-3">
                            <div className="flex justify-between">
                                <span className="text-gray-300">
                                    New Principal:
                                </span>
                                <span className="font-medium">
                                    {`${
                                        inv.currency === "NGN" ? "₦" : "$"
                                    }${formatNum(state.new_principal)}`}
                                </span>
                            </div>

                            {inv.money_market === 0 && (
                                <>
                                    <div className="flex justify-between">
                                        <span className="text-gray-300">
                                            New Tenure (From Today):
                                        </span>
                                        <span className="font-medium">{`${inv.rem_days} Days`}</span>
                                    </div>

                                    <div className="flex justify-between">
                                        <span className="text-gray-300">
                                            New Maturity Interest:
                                        </span>
                                        <span className="font-medium">
                                            {`${
                                                inv.currency === "NGN"
                                                    ? "₦"
                                                    : "$"
                                            }${formatNum(
                                                state.new_maturity_interest
                                            )}`}
                                        </span>
                                    </div>
                                </>
                            )}
                        </div>

                        {inv.money_market === 0 && (
                            <div className="mt-5 pt-4 border-t border-gray-700">
                                <div className="flex justify-between">
                                    <span className="text-gray-300">
                                        Penalty Fee:
                                    </span>
                                    <span className="font-medium">
                                        {`${
                                            inv.currency === "NGN" ? "₦" : "$"
                                        }${formatNum(
                                            state.termination_interest_amount
                                        )}`}
                                    </span>
                                </div>
                            </div>
                        )}
                    </div>
                </DialogContent>
                <DialogActions sx={{ justifyContent: "center" }}>
                    <CustomButton
                        dark
                        loading={inProgress}
                        disabled={
                            state.downsize_amount < 100 ||
                            state.new_principal < 100
                        }
                        className="w-full max-w-[26rem] my-4 justify-center mx-2"
                        type="submit"
                    >
                        <span>Continue</span>
                        {!inProgress && <RightArrIcon className="fill-white" />}
                    </CustomButton>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default PartLiquidation;
