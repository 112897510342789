import React, { useCallback, useEffect, useRef, useState } from "react";
import Greeting from "../../../components/Greeting";
import {
    corporateAccountInfoInputs,
    corporateAccountInfoSchema,
    getDefault,
    individualAccountInfoInputs,
    individualAccountInfoSchema,
} from "../../../services/reg-inputs";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import CustomInput from "../../../components/CustomInput";
import CustomButton from "../../../components/CustomButton";
import RightArrIcon from "../../../components/Icons/RightArrIcon";
import axios from "axios";
import { BURL } from "../../../services/base";
import { setProfile } from "../../../slices/userSlice";
import { toast } from "react-toastify";
import LoadingIcon from "../../../components/Icons/LoadingIcon";
import ProfileGoTo from "../../../components/ProfileGoTo";

const BanksAndCards = () => {
    const { data, customer } = useSelector((state) => state.profile);
    const [inProgress, setInProgress] = useState(false);
    const [state, setState] = useState({
        gettingName: false,
        accountName: "",
        accountNameErr: "",
        verifyingBVN: false,
        bvnVerified: false,
    });
    const isCORP = customer?.label === "CORP";

    const dispatch = useDispatch();
    const initialValues = getDefault(
        isCORP ? corporateAccountInfoInputs : individualAccountInfoInputs,
        { ...data, ...customer }
    );
    const accountInfo = useFormik({
        validationSchema: isCORP
            ? corporateAccountInfoSchema
            : individualAccountInfoSchema,
        initialValues,
        onSubmit: (values) => {
            if (!state.accountName) {
                getAccountName();
                return;
            }
            if (!state.bvnVerified) return;
            if (state.verifyingBVN || state.gettingName) return;

            const body = { ...data, ...customer, ...values };
            setInProgress(true);
            axios
                .post(`${BURL}edit-customer`, {
                    ...body,
                    customer_id: customer.id,
                    rm_id: customer.rm_id,
                })
                .then((res) => {
                    if (!res?.data?.success) throw new Error(res);
                    axios
                        .post(`${BURL}get-customer`)
                        .then((res) => {
                            if (!res?.data?.success) throw new Error(res);
                            setInProgress(false);
                            toast.success("Updated successfully");
                            dispatch(setProfile(res.data.message));
                        })
                        .catch((err) => {
                            toast.error("Can't fetch updated user profile");
                        });
                })
                .catch((err) => {
                    setInProgress(false);
                    if (err?.response?.data) {
                        toast.error(err?.response?.data?.message);
                    } else {
                        toast.error("An error occured");
                    }
                });
        },
    });

    const didMount = useRef(false);
    const getAccountName = useCallback(() => {
        // console.log(didMount.current);
        if (didMount.current) {
            if (
                accountInfo.values?.account_number?.length !== 10 ||
                !accountInfo.values.account_number
            )
                return;
            // console.log("Done");
            // return;
            setState((e) => ({ ...e, gettingName: true }));
            axios
                .post(`${BURL}account-lookup`, {
                    bank_code: accountInfo.values.bank_code,
                    account_number: accountInfo.values.account_number,
                })
                .then((res) => {
                    if (!res?.data?.success) throw new Error(res);
                    const acctName = res?.data?.data.account_name;
                    setState((e) => ({
                        ...e,
                        accountName: acctName,
                        accountNameErr: "",
                        gettingName: false,
                    }));
                    accountInfo.setFieldValue("account_name", acctName);
                })
                .catch((err) => {
                    if (err?.response?.data) {
                        toast.error(err?.response?.data?.message);
                    } else {
                        toast.error("An error occured");
                    }
                    setState((e) => ({
                        ...e,
                        accountName: "",
                        accountNameErr:
                            err?.response?.data?.message ?? "An error occured",
                        gettingName: false,
                    }));
                    accountInfo.setFieldValue("account_name", "");
                });
        } else {
            didMount.current = true;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountInfo.values.bank_code, accountInfo.values.account_number]);

    useEffect(() => {
        getAccountName();
    }, [getAccountName]);

    const verifyBVN = () => {
        if (!state.accountName) {
            toast.error("You need to verify your bank details first");
            return;
        }

        if (!accountInfo.values?.bvn) return;
        if (accountInfo.values?.bvn?.length < 11) return;
        setState((e) => ({ ...e, verifyingBVN: true }));
        axios
            .post(`${BURL}bvn-verification`, {
                bvn: accountInfo.values.bvn,
                account_name: state.accountName,
            })
            .then((res) => {
                if (!res?.data?.success) throw new Error(res);
                // console.log("No error");
                setState((e) => ({
                    ...e,
                    bvnVerified: true,
                    verifyingBVN: false,
                }));
            })
            .catch((err) => {
                // console.log("error");
                if (err?.response?.data) {
                    toast.error(err?.response?.data?.message);
                } else {
                    toast.error("An error occured");
                }
                setState((e) => ({
                    ...e,
                    bvnVerified: false,
                    verifyingBVN: false,
                }));
            });
    };

    useEffect(() => {
        setState((e) => ({
            ...e,
            bvnVerified: false,
            verifyingBVN: false,
        }));
    }, [accountInfo.values.bvn]);

    const [inputs, setInputs] = useState(
        isCORP ? corporateAccountInfoInputs : individualAccountInfoInputs
    );

    useEffect(() => {
        axios
            .get(`${BURL}bank-list`)
            .then((response) => {
                const banks = response.data
                    .map((bank, index) => ({
                        text: bank.name,
                        value: bank.code,
                    }))
                    .sort((a, b) => {
                        let nameA = a.text.toUpperCase(); // Convert names to uppercase for case-insensitive comparison
                        let nameB = b.text.toUpperCase();
                        if (nameA < nameB) {
                            return -1;
                        }
                        if (nameA > nameB) {
                            return 1;
                        }
                        return 0; // Names are equal
                    });
                setInputs((e) =>
                    e.map((each, i) =>
                        each.name === "bank_code"
                            ? { ...each, options: banks }
                            : each
                    )
                );
            })
            .catch((error) => {
                toast.error("Can't fetch bank list, please refresh");
                console.error("Failed to fetch bank data:", error);
            });
    }, []);

    return (
        <div>
            <Greeting
                showBack
                primaryBack
                noPre
                head={`Bank Details`}
                sub={"Please provide your banking information"}
            />
            <div>
                <form
                    onSubmit={accountInfo.handleSubmit}
                    className="flex w-full lg:w-2/3 xl:w-1/2 flex-wrap"
                >
                    {inputs.map((inputProps, i) => (
                        <div
                            key={i}
                            className={`px-3 ${
                                inputProps.class
                                    ? inputProps.class
                                    : "lg:[&:nth-child(2)]:w-1/3 lg:[&:nth-child(3)]:w-2/3 lg:w-1/2"
                            } w-full`}
                        >
                            <CustomInput
                                initialValues={initialValues}
                                icon={
                                    inputProps.name === "account_name" &&
                                    state.gettingName ? (
                                        <span className="text-[#c5c5c5b7]">
                                            <LoadingIcon />
                                        </span>
                                    ) : inputProps.name === "bvn" &&
                                      state.bvnVerified ? (
                                        <svg
                                            className="rotate-6"
                                            width="18"
                                            height="18"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M2.0813 13.1315L7.78618 17.6954C7.93939 17.8215 8.11879 17.912 8.31135 17.9601C8.5039 18.0083 8.70476 18.0129 8.89932 17.9737C9.09575 17.9368 9.28193 17.8581 9.4452 17.7428C9.60847 17.6276 9.745 17.4785 9.8455 17.3058L18.7785 2"
                                                stroke="green"
                                                strokeWidth="2.25203"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                    ) : (
                                        ""
                                    )
                                }
                                // className="min-w-full"
                                {...inputProps}
                                key={i}
                                formik={accountInfo}
                            />
                        </div>
                    ))}
                    <div className="flex space-x-2 pt-9 pb-2 w-4/12 lg:w-3/12  px-3">
                        <CustomButton
                            type="button"
                            className="w-full text-xs"
                            loading={state.verifyingBVN}
                            onClick={verifyBVN}
                        >
                            <span>Verify</span>
                        </CustomButton>
                    </div>
                    <div className="flex space-x-2 py-6 w-full px-3">
                        <CustomButton
                            type="submit"
                            className="w-full disabled:cursor-not-allowed"
                            loading={inProgress}
                        >
                            <span>Update Details</span>
                            {!inProgress && <RightArrIcon />}
                        </CustomButton>
                    </div>
                    <div className="flex justify-between gap-3 py-4 w-full px-3">
                        <ProfileGoTo prev to={"/profile/personal"} />
                        <ProfileGoTo next to={"/profile/others"} />
                    </div>
                </form>
            </div>
        </div>
    );
};

export default BanksAndCards;
