import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import CustomButton from "../CustomButton";
import RightArrIcon from "../Icons/RightArrIcon";
import axios from "axios";
import { BURL, formatNum } from "../../services/base";
import { useDispatch, useSelector } from "react-redux";
import CurrencyFormat from "react-currency-format";
import { setCashBal, setTransactions } from "../../slices/userSlice";
import BankNoteIcon from "../Icons/BankNoteIcon";
import { toast } from "react-toastify";
import LoadingIcon from "../Icons/LoadingIcon";
import WalletWarning from "../WalletWarning";

const RequestFund = ({ open, setOpen, wallet: currency, amount_limit }) => {
    const { cash_balance, data } = useSelector((state) => state.profile);
    const [inProgress, setInProgress] = useState(false);
    const [amountFigure, setamountFigure] = useState(0);
    const [err, setErr] = useState(null);
    const [step, setStep] = useState(0);
    const [status, setStatus] = useState("");

    const successRes = (text) => {
        setOpen(false);
        Swal.fire({
            title: "Successful",
            text,
            icon: "success",
            confirmButtonText: "Continue",
        });
    };

    const dispatch = useDispatch();
    const submit = (e) => {
        e.preventDefault();
        if (!amountFigure) {
            setErr("Amount is required");
            return;
        } else if (
            Number(amountFigure) >
            Number(
                currency === "naira"
                    ? cash_balance?.customerNairaBalance
                    : cash_balance?.customerDollarBalance
            )
        ) {
            return;
        }
        setErr(null);
        setInProgress(true);
        axios
            .post(`${BURL}request-${currency}-balance`, {
                amount: Number(amountFigure),
            })
            .then((res) => {
                if (!res?.data?.success) throw new Error(res);
                setInProgress(false);
                successRes(res.data.message);
                axios.post(`${BURL}get-transactions`, {}).then((res) => {
                    if (!res?.data?.success) throw new Error(res);
                    dispatch(setTransactions(res.data.message));
                });
                axios
                    .get(`${BURL}get-cash-balance`)
                    .then((res) => {
                        if (!res?.data?.success) throw new Error(res);
                        dispatch(setCashBal(res.data.message));
                    })
                    .catch((err) => {
                        toast.error(
                            "Can't update cash balance, please refresh."
                        );
                    });
            })
            .catch((err) => {
                setInProgress(false);
                // console.log(err);
                if (err?.response?.data) {
                    toast.error(err?.response?.data?.message);
                } else if (err?.data?.message) {
                    toast.error(err?.data?.message);
                } else {
                    toast.error("Not connected, please try again.");
                }
            });
    };

    const handleChange = (values) => {
        setamountFigure(values.value);
        if (
            Number(values.value) >
            Number(
                currency === "naira"
                    ? cash_balance?.customerNairaBalance
                    : cash_balance?.customerDollarBalance
            )
        ) {
            setErr("You can't request more than your balance");
        } else {
            setErr(null);
        }
    };

    const [fetchingAccount, setFetchingAccount] = useState(false);
    const [err2, setErr2] = useState(null);
    // const [wallet, setWallet] = useState(null);

    useEffect(() => {
        setStatus(data.status);

        setErr2(null);
        if (open) {
            // setFetchingAccount(true);
            // axios.get(`${BURL}get-wallet-info`).then(res => {
            // 	if (!res?.data?.success) throw new Error(res);
            // 	setFetchingAccount(false);
            // 	// console.log(res.data);
            // 	// successRes();
            // 	// axios.get(`${BURL}get-transactions`).then(res => {
            // 	// 	if (!res?.data?.success) throw new Error(res);
            // 	// 	dispatch(setTransactions(res.data.message))
            // 	// })
            // }).catch(err => {
            // 	setFetchingAccount(false);
            // 	let error = err?.response?.data?.message ?? err?.data?.message;
            // 	if (error) {
            // 		setErr2(error);
            // 	} else {
            // 		setErr2("An error occured, please try again.")
            // 	}
            // })
        }
    }, [open, dispatch, data.status]);

    return (
        <>
            {status.toUpperCase() === "ACTIVE" ? (
                <Dialog
                    fullWidth
                    maxWidth={"sm"}
                    PaperProps={{
                        sx: { maxWidth: 480, borderRadius: 4 },
                        className:
                            "bg-[#F7F7F7] dark:bg-trov-dark dark:text-white",
                    }}
                    open={open}
                    onClose={() => {
                        setOpen(false);
                        setStep(0);
                    }}
                >
                    {step === 1 && (
                        <DialogTitle className="text-center">
                            <h3 className="dialog-title ">Withdraw to Bank</h3>
                            <div className="flex flex-col mt-4 items-center justify-center">
                                <span className="text-sm text-[#BCBCBC] dark:text-[#ffffff4d]">
                                    {data.account_number}
                                </span>
                                <span className="text-sm">
                                    {data.account_name}
                                </span>
                                <span className="text-base font-bold text-[#BCBCBC] dark:text-[#ffffff4d]">
                                    Balance -{" "}
                                    {currency === "naira"
                                        ? `₦ ${formatNum(
                                              cash_balance?.customerNairaBalance
                                          )}`
                                        : `$ ${formatNum(
                                              cash_balance?.customerDollarBalance
                                          )}`}
                                </span>
                            </div>
                            {/* <h3 className='dialog-sub'>Current Balance - {(currency === 'naira' ? `₦ ${formatNum(cash_balance?.customerNairaBalance)}` : `$ ${formatNum(cash_balance?.customerDollarBalance)}`)}</h3> */}
                        </DialogTitle>
                    )}
                    <form onSubmit={submit}>
                        <DialogContent>
                            {step === 0 && (
                                <div className="text-black mt-4 px-4 flex flex-col items-center justify-center dark:text-white text-xs py-7 mb-7 text-center w-full leading-6">
                                    <>
                                        {fetchingAccount ? (
                                            <LoadingIcon />
                                        ) : err2 ? (
                                            <WalletWarning err={err2} />
                                        ) : (
                                            <>
                                                <h3 className="dialog-title -mt-5">
                                                    Withdrawal Policies
                                                </h3>
                                                <div>
                                                    {/* <span className="font-bold">Note:</span> */}
                                                    <ul className="text-sm my-5 text-justify">
                                                        <li className="mb-2">
                                                            Automatic
                                                            withdrawals are only
                                                            available for
                                                            amounts not up to ₦
                                                            {formatNum(
                                                                amount_limit
                                                            )}
                                                            . For amounts
                                                            exceeding this
                                                            limit, approval will
                                                            be required before
                                                            the transaction can
                                                            be completed
                                                            successfully.
                                                        </li>
                                                        {/* <li className="list-disc mb-2">
																You will not be able to initiate another withdrawal until 12 hours after the previous transaction.
															</li>
															<li className="list-disc mb-2">
																No withdrawal can be made if there is a pending withdrawal request.
															</li>
															<li className="list-disc mb-2">
																Be informed that we will not be held liable for mistake in withdrawing in to a wrong account.
															</li> */}
                                                    </ul>
                                                </div>
                                                <CustomButton
                                                    onClick={() => setStep(1)}
                                                    type="button"
                                                    className={`mx-auto mt-4 w-full -mb-5`}
                                                >
                                                    <span>Continue</span>
                                                    <RightArrIcon className="fill-white" />
                                                </CustomButton>
                                            </>
                                        )}
                                    </>
                                </div>
                            )}

                            {step === 1 && (
                                <div className="dark:text-white">
                                    <div className="flex flex-col space-y-1 pb-4 w-full max-w-sm mx-auto">
                                        <label className="text-sm font-semibold">
                                            Amount
                                        </label>
                                        <div className="relative flex items-center ">
                                            <span className="absolute left-3">
                                                <BankNoteIcon />
                                            </span>
                                            <CurrencyFormat
                                                decimalScale={0}
                                                allowNegative={false}
                                                thousandSeparator={true}
                                                prefix={
                                                    currency === "naira"
                                                        ? "₦ "
                                                        : "$ "
                                                }
                                                onValueChange={handleChange}
                                                placeholder="Enter Amount"
                                                className={`w-full border border-black dark:border-white rounded-full bg-transparent h-9 pr-4 pl-9 outline-none transition text-sm ${
                                                    err && "border-red-500"
                                                }`}
                                            />
                                        </div>
                                        {err && (
                                            <div className="block max-w-sm text-red-500 text-xs">
                                                {err}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </DialogContent>
                        {step === 1 && (
                            <DialogActions sx={{ justifyContent: "center" }}>
                                <CustomButton
                                    dark
                                    loading={inProgress}
                                    className="flex space-x-3 items-center w-full max-w-sm rounded-full mb-5 md:mb-7 justify-center"
                                    type="submit"
                                >
                                    <span>Submit</span>
                                    <RightArrIcon className="fill-white" />
                                </CustomButton>
                            </DialogActions>
                        )}
                    </form>
                </Dialog>
            ) : (
                <Dialog
                    fullWidth
                    maxWidth={"sm"}
                    PaperProps={{
                        sx: { maxWidth: 480, borderRadius: 4 },
                        className:
                            "bg-[#F7F7F7] dark:bg-trov-dark dark:text-white",
                    }}
                    open={open}
                    onClose={() => {
                        setOpen(false);
                        setStep(0);
                    }}
                >
                    <DialogTitle className="text-center">
                        <h3 className="dialog-title mt-4">Withdraw to Bank</h3>
                    </DialogTitle>
                    <DialogContent>
                        <div className="font-semibold px-4 flex flex-col items-center justify-center  text-red-500 text-sm py-7 mb-7 text-center w-full leading-6">
                            Account requires approval prior to withdrawals.
                        </div>
                    </DialogContent>
                </Dialog>
            )}
        </>
    );
};

export default RequestFund;
