import axios from 'axios';
import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {Outlet} from 'react-router';
import {toast} from 'react-toastify';
import {BURL} from '../services/base';
import {setCompany} from '../slices/userSlice';
import LoadingIcon from './Icons/LoadingIcon';

const LoaderWrap = () => {
	const dispatch = useDispatch();
	const fetchCompanyDetails = useCallback(async () => {
		try {
			const response = await axios.get(`${BURL}get-company`);
			const company = response?.data?.message?.company;
			if (!company) return;
			dispatch(setCompany({
				logo: company.logo_url
			}));
		} catch (error) {
			toast.error("Failed to fetch company details.");
		}
	}, [dispatch]);

	const loader = <div className='flex text-primary h-screen items-center justify-center'>
		<LoadingIcon className='fill-primary' />
	</div>

	const [currentView, setCurrentView] = useState(loader);
	useEffect(() => {
		fetchCompanyDetails();
		const timeout = setTimeout(() => {
			setCurrentView(<Outlet />)
		}, 2000);
		return () => {
			clearTimeout(timeout);
		}
	}, [fetchCompanyDetails])

	return currentView
}

export default LoaderWrap