import React, {useEffect, useState} from "react";
import AuthPattern from "../components/Patterns/AuthPattern";
import {useNavigate} from "react-router";
import Chaticon from "../components/Icons/Chaticon";
import MemoWhatsIcon from "../components/Icons/WhatsIcon";
import MemoPhoneIcon from "../components/Icons/PhoneIcon";
import MemoMailIcon from "../components/Icons/MailIcon";
import {BURL} from "../services/base";
import axios from "axios";
import {toast} from "react-toastify";


const HelpAndSupport = () => {
    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    };

    const [company, setCompany] = useState(null);

    useEffect(() => {
        const fetchCompanyDetails = async () => {
            try {
                const response = await axios.get(`${BURL}get-company`);
                const company = response?.data?.message?.company;
                if (company) {
                    setCompany(company);
                }
            } catch (error) {
                toast.error("Failed to fetch company details.");
            }
        };

        fetchCompanyDetails();
    }, []);

    return (
        <>
            <div className="max-w-[65rem] lgpx-10 px-5 mx-auto flex flex-col min-h-full w-full relative">
                <div className="flex justify-between flex-wrap gap-x-10 gap-y-5 py-10 items-center">
                    <img
                        alt="logo"
                        src={company?.logo_url}
                        className="w-36 dark:[filter:invert(100%)_brightness(1000%)]"
                    />
                </div>
            </div>
            <div className="flex mt-10 xl:pt-16 justify-center p-5 sm:px-10">
                <div className="w-full max-w-[35rem]">
                    <h3 className="text-xl flex items-center gap-4 font-medium">
                        <button onClick={goBack} title="Go Back">
                            <svg
                                className="h-4"
                                viewBox="0 0 8 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M6.5 1.1665L1.5 6.99984L6.5 12.8332"
                                    className="stroke-current"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </button>
                        Help & Support
                    </h3>

                    <div className="flex flex-col text-sm gap-6 py-6">
                        <SupportBox title={"Chat"} respTime={"1 min"}>
                            <ChatItem
                                link="https://tawk.to/chat/661ffc07a0c6737bd12d2b93/1hrmf0vf1"
                                icon={<Chaticon />}
                                medium={"Live Chat"}
                                desc={"Start a conversation on Live Chat"}
                            />
                            <ChatItem
                                icon={<MemoWhatsIcon />}
                                medium={"Whatsapp"}
                                desc={"Start a conversation on Whatsapp"}
                                color={"green"}
                            />
                        </SupportBox>
                        <SupportBox title={"Email"} respTime={"12 hrs"}>
                            <ContactLine
                                link="mailto:customercare@tro-vestassetmngt.com"
                                contact={"customercare@tro-vestassetmngt.com"}
                                icon={<MemoMailIcon />}
                                actionText={"Email"}
                            />
                        </SupportBox>
                        <SupportBox title={"Call"} respTime={"2 min"}>
                            <ContactLine
                                contact={"+234 810 **** ***"}
                                icon={<MemoPhoneIcon />}
                                actionText={"Call"}
                            />
                        </SupportBox>
                    </div>
                </div>
            </div>
        </>
    );
};

const ContactLine = ({contact, icon, actionText, link}) => {
    return (
        <div className="flex gap-3 justify-between items-center relative">
            <h3 className="text-sm font-medium">{contact}</h3>
            <a
                href={link}
                className={`rounded-lg cursor-pointer hover:opacity-70 transition min-w-[5.5rem] h-9 gap-2 flex text-primary items-center justify-center bg-primary/10`}
            >
                {icon} {actionText}
            </a>
        </div>
    );
};

const SupportBox = ({title, respTime, children}) => {
    return (
        <div className="flex flex-col gap-1">
            <h3 className="dark:text-white/70 text-xs font-medium">{title}</h3>
            <div className="rounded-2xl flex flex-col gap-4 overflow-hidden relative dark:bg-dark/50 dark:shadow-none shadow-[1px_2px_20px_5px_#f3f3f3]  p-4 lg:p-6">
                <AuthPattern
                    style={{
                        height: "auto",
                        top: 0,
                        left: 0,
                        bottom: 0,
                        marginTop: "auto",
                        marginBottom: "auto",
                        opacity: "0.8",
                    }}
                />
                {/* <span className='bg-dark/5 dark:bg-white/10 rounded-md px-2 text-xs py-0.5 w-fit'>Avg. Response time: {respTime}</span> */}
                {children}
            </div>
        </div>
    );
};

const ChatItem = ({icon, medium, desc, color, link}) => {
    return (
        <a
            href={link}
            target="_blank"
            rel="noreferrer"
            className="w-full cursor-pointer hover:opacity-70 transition duration-300 text-left flex gap-3 items-center relative"
        >
            <div
                className={`rounded-full h-11 w-11 flex items-center justify-center ${color === "green" ? "bg-green-700/10" : "bg-primary/10 "
                    }`}
            >
                {icon}
            </div>
            <div>
                <h3 className="text-sm font-medium">{medium}</h3>
                <p className="text-xs dark:text-white/60">{desc}</p>
            </div>
        </a>
    );
};

export default HelpAndSupport;
