import axios from "axios";
import {useFormik} from "formik";
import React, {useState} from "react";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import * as Yup from "yup";
import CustomButton from "../components/CustomButton";
import CustomInput from "../components/CustomInput";
import AtIcon from "../components/Icons/AtIcon";
import RightArrIcon from "../components/Icons/RightArrIcon";
import {BURL} from "../services/base";

const ForgotPassword = () => {
    const [inProgress, setInProgress] = useState(false);
    const {company: {logo}} = useSelector(state => state.profile);

    const [res, setRes] = useState(null);

    const emailSuccess = () => {
        setRes({
            type: "success",
            message:
                "Please check your email inbox and follow the instructions provided in the recovery email to reset your password.",
        });
    };

    const emailError = (message) => {
        setRes({type: "error", message: message});
    };

    const formik = useFormik({
        validationSchema: Yup.object({
            email: Yup.string()
                .email("Provide a valid email address")
                .required("Email is required to set a new Password"),
        }),
        initialValues: {email: ""},
        onSubmit: (values) => {
            setInProgress(true);
            setRes(null);
            axios
                .post(`${BURL}forgot-password`, values)
                .then((res) => {
                    if (!res?.data?.success) throw new Error(res);
                    setInProgress(false);
                    emailSuccess();
                })
                .catch((err) => {
                    setInProgress(false);
                    if (err?.response?.data) {
                        emailError(err?.response?.data?.message);
                    } else {
                        emailError("An error occured, please try again.");
                    }
                });
        },
    });
    return (
        <div className="relative min-h-screen bg-fixed flex bg-blend-color-burn bg-[url('./assets/authPattern.svg')] dark:bg-[url('./assets/authPattern2.svg')] bg-cover">
            <div className="max-w-[65rem] px-10 lg:px-5 mx-auto flex flex-col min-h-full w-full relative">
                <div className="flex justify-between flex-wrap gap-x-10 gap-y-5 py-10 items-center">
                    <img
                        alt="logo"
                        src={logo}
                        className="w-36 dark:[filter:invert(100%)_brightness(1000%)]"
                    />
                    <p className="text-sm text-[#8B8B8C]">
                        <Link to="/register">
                            Don’t have an account?{" "}
                            <span className="text-primary">Sign Up</span>
                        </Link>
                    </p>
                </div>
                <div className="w-full relative flex h-[70vh] flex-col items-center justify-center">
                    <form
                        onSubmit={formik.handleSubmit}
                        className="m-auto max-w-[30rem] w-full p-5 lg:px-10 rounded-lg"
                    >
                        {res && (
                            <div
                                className={`w-full p-5 dark:bg-white text-black text-sm border ${res?.type === "error"
                                        ? "border-red-600"
                                        : ""
                                    } rounded-2xl mb-5`}
                            >
                                {res?.message}
                            </div>
                        )}
                        <h3 className="text-2xl font-semibold">
                            Recover my Password
                        </h3>
                        <p className="text-xs mb-5 text-[#8B8B8C]">
                            Don’t worry! we can fix this.
                        </p>
                        <CustomInput
                            icon={<AtIcon />}
                            name="email"
                            label="Email"
                            type="email"
                            formik={formik}
                        />
                        <div className="fm-group mt-3">
                            <CustomButton
                                loading={inProgress}
                                type="submit"
                                className="py-3 font-medium justify-center text-md text-white "
                            >
                                <span>Get Reset Link</span>
                                {!inProgress && <RightArrIcon />}
                            </CustomButton>
                        </div>
                        <p className="text-center text-sm w-full font-medium mt-5">
                            {" "}
                            <Link to="/login">Go back to login</Link>{" "}
                        </p>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ForgotPassword;
