import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@mui/material";
import axios from "axios";
import { useFormik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { BURL, formatNum } from "../../services/base";
import CustomButton from "../CustomButton";
import RightArrIcon from "../Icons/RightArrIcon";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { setInvestments } from "../../slices/userSlice";
import CurrencyFormat from "react-currency-format";
import BankNoteIcon from "../Icons/BankNoteIcon";
import moment from "moment";

const BookInvestment = ({
    open,
    setOpen,
    id,
    rate,
    name,
    min_amount,
    min_tenure,
    currency,
    money_market,
}) => {
    rate = Number(rate);
    const wht_status = 1;

    const [inProgress, setInProgress] = useState(false);
    const { cash_balance } = useSelector((state) => state.profile);

    const [state, setState] = useState({
        tenure: 0,
        principal: 0,
        maturity_interest: 0,
    });

    const [errText, setErrText] = useState("");
    const [err, setErr] = useState(null);

    const bookInvSchema = Yup.object({
        startDate: Yup.string().required(),
        endDate: money_market
            ? Yup.string().notRequired()
            : Yup.string().required(),
    });

    const successRes = () => {
        setOpen(false);
        Swal.fire({
            title: "Investment Successful",
            text: "Thank you for Investing with us",
            icon: "success",
            // didClose: () => setOpen(false),
            confirmButtonText: "Continue",
        });
    };
    const errorRes = (res) => {
        Swal.fire({
            title: "Can't Book Investment",
            text: res,
            icon: "error",
            // didClose: () => setOpen(false),
            confirmButtonText: "Okay",
        });
    };
    const dispatch = useDispatch();

    const book = useFormik({
        validationSchema: bookInvSchema,
        initialValues: {
            endDate: "",
            startDate: moment().format("YYYY-MM-DD"),
        },
        onSubmit: (values) => {
            if (errText && !money_market) return;
            if (!state.principal) {
                setErr("Amount is required");
                return;
            } else if (Number(state.principal) < min_amount) {
                setErr(`Minimum amount for this product type is ${min_amount}`);
                return;
            } else if (
                currency === "NGN" &&
                Number(state.principal) > cash_balance?.customerNairaBalance
            ) {
                setErr(`Insufficient naira balance`);
                return;
            } else if (
                currency === "USD" &&
                Number(state.principal) > cash_balance?.customerDollarBalance
            ) {
                setErr(`Insufficient dollar balance`);
                return;
            }
            setErr(null);
            const data = {
                principal: state.principal,
                product_id: id,
                tenure: state.tenure,
            };
            setInProgress(true);
            axios
                .post(`${BURL}add-investment`, data)
                .then((res) => {
                    if (!res?.data?.success) throw new Error(res);
                    setInProgress(false);
                    axios
                        .get(`${BURL}get-investments`)
                        .then((res) => {
                            if (!res?.data?.success) throw new Error(res);
                            dispatch(setInvestments(res?.data?.message));
                        })
                        .catch((err) => {
                            toast.error("Can't update investment list");
                        });
                    successRes();
                })
                .catch((err) => {
                    setInProgress(false);
                    if (err?.response?.data) {
                        errorRes(err?.response?.data?.message);
                    } else {
                        errorRes("An error occured, please try again.");
                    }
                });
        },
    });

    // Calculate interest
    const calculateInterest = useCallback(
        (principal = state.principal, tenure = state.tenure) => {
            if (!principal || !rate || !tenure) return;

            const start_date = moment(book.values.startDate).format(
                "YYYY-MM-DD"
            );
            const maturity_date = moment(book.values.endDate).format(
                "YYYY-MM-DD"
            );

            const start_year = moment(start_date).format("YYYY");
            const maturity_year = moment(maturity_date).format("YYYY");

            const start_year_leap = isLeapYear(parseInt(start_year));
            const maturity_year_leap = isLeapYear(parseInt(maturity_year));

            const january1_next_year = moment(start_date)
                .startOf("year")
                .add(1, "year");

            const t1 = Math.min(
                Math.ceil(
                    moment
                        .duration(
                            moment(january1_next_year)
                                .subtract(1, "day")
                                .diff(start_date)
                        )
                        .asDays()
                ),
                tenure
            );
            const t2 = Math.max(tenure - t1, 0);

            const year_num1 = start_year_leap ? 36600 : 36500;
            const year_num2 = maturity_year_leap ? 36600 : 36500;

            const part1 =
                (principal * rate * t1) / year_num1 +
                (principal * rate * t2) / year_num2;
            const part2 = part1 * 0.1;

            if (wht_status === 1) {
                setState((prev) => ({
                    ...prev,
                    maturity_interest: part1 - part2,
                    wht_interest: part2,
                }));
            } else {
                setState((prev) => ({
                    ...prev,
                    maturity_interest: part1,
                    wht_interest: 0,
                }));
            }
        },
        [
            book.values.endDate,
            book.values.startDate,
            rate,
            state.principal,
            state.tenure,
        ]
    );

    const handleChange = (values) => {
        if (Number(values.value) < min_amount) {
            setErr(`Minimum amount for this product type is ${min_amount}`);
        } else {
            setErr(null);
        }
        setState((state) => ({ ...state, principal: values.floatValue }));
        calculateInterest(values.floatValue);
    };

    useEffect(() => {
        if (book.values.startDate && book.values.endDate) {
            const tenure =
                (new Date(book.values.endDate) -
                    new Date(book.values.startDate)) /
                (1000 * 3600 * 24);
            if (tenure < 1) {
                setErrText("Day difference cannot be less than 1");
            } else if (tenure < min_tenure) {
                setErrText(
                    `Minumum number of days for this product is ${min_tenure}`
                );
            } else {
                setErrText("");
            }
            setState((state) => ({ ...state, tenure }));
            calculateInterest(state.principal, tenure);
        }
    }, [
        book.values.startDate,
        book.values.endDate,
        min_tenure,
        calculateInterest,
        state.principal,
    ]);

    // Check if a year is a leap year
    const isLeapYear = (year) => {
        return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
    };

    return (
        <>
            <Dialog
                fullWidth
                maxWidth={"sm"}
                PaperProps={{
                    sx: { maxWidth: 480, borderRadius: 4 },
                    className: "bg-[#F7F7F7] dark:bg-trov-dark dark:text-white",
                }}
                open={open}
                onClose={() => setOpen(false)}
            >
                <DialogTitle className="">
                    <h3 className="dialog-title">Book Investment</h3>
                    <h3 className="dialog-sub">{name}</h3>
                </DialogTitle>
                <form onSubmit={book.handleSubmit}>
                    <DialogContent>
                        <DialogContentText className="">
                            <div className="w-full dark:text-white max-w-sm mx-auto pt-4">
                                <div className="mb-5">
                                    <div className="flex items-center relative flex-wrap">
                                        <span className="absolute left-3">
                                            <BankNoteIcon />
                                        </span>
                                        <CurrencyFormat
                                            autoFocus
                                            thousandSeparator={true}
                                            prefix={
                                                currency === "NGN" ? "₦ " : "$ "
                                            }
                                            onValueChange={handleChange}
                                            placeholder="Enter Amount"
                                            className={`w-full max-w-md border border-black dark:border-white rounded-full h-9 pr-4 pl-9 outline-none bg-transparent focus:border-primary transition text-sm ${
                                                err && "border-red-500"
                                            }`}
                                        />
                                    </div>
                                    {err && (
                                        <small className="absolute block ml-2 text-red-500">
                                            {err}
                                        </small>
                                    )}
                                </div>
                                {!money_market && (
                                    <>
                                        <div className="flex flex-wrap sm:flex-nowrap sm:space-x-2 mt-4">
                                            <div className="w-full sm:w-[unset]">
                                                <label className="ml-2 text-xs font-bold">
                                                    Start Date
                                                </label>
                                                <input
                                                    disabled
                                                    name="startDate"
                                                    type="date"
                                                    value={
                                                        book.values.startDate
                                                    }
                                                    className={`w-full border border-black dark:border-white bg-transparent rounded-full h-9 px-4 mb-4 lg:mb-2 outline-none focus:border-primary transition text-sm ${
                                                        book.errors.startDate &&
                                                        "border-red-500"
                                                    }`}
                                                />
                                            </div>
                                            <div className="w-full sm:w-[unset]">
                                                <label className="ml-2 text-xs font-bold">
                                                    End Date
                                                </label>
                                                <input
                                                    onChange={book.handleChange}
                                                    name="endDate"
                                                    type="date"
                                                    value={book.values.endDate}
                                                    className={`w-full border border-black dark:border-white bg-transparent rounded-full h-9 px-4 mb-4 lg:mb-2 outline-none focus:border-primary transition text-sm ${
                                                        book.errors.endDate &&
                                                        "border-red-500"
                                                    }`}
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full text-sm pl-2 flex justify-between gap-2">
                                            <span>{state.tenure} Days</span>
                                            <span>
                                                Maturity Interest:{" "}
                                                {formatNum(
                                                    state.maturity_interest
                                                )}
                                            </span>
                                        </div>
                                    </>
                                )}
                                {errText && (
                                    <div className="w-full text-xs text-red-500">
                                        {errText}
                                    </div>
                                )}
                            </div>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions sx={{ justifyContent: "center" }}>
                        <CustomButton
                            dark
                            loading={inProgress}
                            className="w-full max-w-sm md:my-5 justify-center mx-2"
                            type="submit"
                        >
                            <span>Continue</span>
                            {!inProgress && (
                                <RightArrIcon className="fill-white" />
                            )}
                        </CustomButton>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
};

export default BookInvestment;
