import axios from "axios";
import {useFormik} from "formik";
import React, {useState} from "react";
import {useSelector} from "react-redux";
import {Link, useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import AuthThemeControl from "../components/AuthThemeControl";
import CustomButton from "../components/CustomButton";
import CustomInput from "../components/CustomInput";
import Nerthbox from "../components/Icons/Nerthbox";
import RightArrIcon from "../components/Icons/RightArrIcon";
import {BURL} from "../services/base";
import {getDefault} from "../services/reg-inputs";
import {signInInputs, signInSchema} from "../services/signin-input";

const Login = () => {
    const navigate = useNavigate();
    // const rememberToken = localStorage.getItem("remember_token");
    const {company: {logo}} = useSelector(state => state.profile);
    const [inProgress, setInProgress] = useState(false);

    const signIn = useFormik({
        validationSchema: signInSchema,
        initialValues: getDefault(signInInputs),
        onSubmit: (values) => {
            loginAction(values);
        },
    });

    const refreshAction = () => {
        setInProgress(true);
        let token = localStorage.getItem("remember_token");
        axios
            .post(`${BURL}refresh-token`, {token})
            .then((res) => {
                if (!res?.data?.success) throw new Error(res);
                setInProgress(false);
                localStorage.setItem("token", res.data.token);
                localStorage.setItem("remember_token", res.data.token);

                navigate("/dashboard");
            })
            .catch((err) => {
                setInProgress(false);
                if (err?.response?.data) {
                    toast.error(err?.response?.data?.message);
                } else {
                    toast.error("An error occured, please try again.");
                }
            });
    };

    const loginAction = (values) => {
        setInProgress(true);
        axios
            .post(`${BURL}login`, values)
            .then((res) => {
                if (!res?.data?.success) throw new Error(res);
                setInProgress(false);
                localStorage.setItem("token", res.data.token);
                localStorage.setItem("remember_token", res.data.token);

                navigate("/dashboard");
            })
            .catch((err) => {
                setInProgress(false);
                if (err?.response?.data) {
                    toast.error(err?.response?.data?.message);
                } else {
                    toast.error("An error occured, please try again.");
                }
            });
    };
    return (
        <div className="relative min-h-screen bg-fixed flex bg-blend-color-burn bg-[url('./assets/authPattern.svg')] dark:bg-[url('./assets/authPattern2.svg')] bg-cover">
            {/* <AuthPattern /> */}
            <div className="max-w-[65rem] px-10 lg:px-5 mx-auto flex flex-col min-h-full w-full relative">
                <div className="flex justify-between flex-wrap gap-x-10 gap-y-5 py-10 items-center">
                    <img
                        alt="logo"
                        src={logo}
                        className="w-36 dark:[filter:invert(100%)_brightness(1000%)]"
                    />
                    <p className="text-sm text-[#8B8B8C]">
                        <Link to="/register">
                            Don’t have an account?{" "}
                            <span className="text-primary">Sign Up</span>
                        </Link>
                    </p>
                </div>
                <AuthThemeControl />
                <div className="w-full my-auto flex gap-x-10 lg:gap-x-28 items-center justify-added">
                    <form
                        onSubmit={signIn.handleSubmit}
                        className="w-full md:w-1/2 mt-auto"
                    >
                        <h3 className="text-2xl font-semibold">Welcome!</h3>
                        <p className="text-gray-500 text-xs mb-5">
                            Let us simplify your financial journey.
                        </p>
                        {signInInputs.map((each) => (
                            <CustomInput
                                key={each.name}
                                {...each}
                                formik={signIn}
                            />
                        ))}
                        <div className="fm-group pt-7">
                            <CustomButton
                                loading={inProgress}
                                type="submit"
                                className="py-3 font-medium justify-center text-md text-white "
                            >
                                <span>Sign In</span>
                                {!inProgress && <RightArrIcon />}
                            </CustomButton>
                        </div>
                        <div className="fm-group flex-row items-center">
                            <Link
                                to="/forgot-password"
                                className="text-xs ml-auto font-medium"
                            >
                                Reset Password
                            </Link>
                        </div>
                        {/* {rememberToken && (
							<div>
							<CustomButton onClick={refreshAction} loading={inProgress} type="submit" className="py-3 font-medium justify-center text-md text-white">
								<span>Refresh Token</span>
							</CustomButton>
							</div>
						)}  */}
                    </form>

                    {/* <div className="hidden md:flex w-1/2 justify-end h-full pt-5">
						<img src={loginImg} alt="LoginImage" className="max-w-sm rounded-lg h-[24rem] object-cover" />
					</div> */}
                </div>
                <div className="py-8 flex justify-between gap-3 flex-wrap">
                    <div className="flex items-center gap-2 text-xs text-gray-600 dark:text-gray-300">
                        Powered By{" "}
                        <Link
                            target="_blank"
                            to={"https://nerthbox.com"}
                            className="text-[#081363] dark:text-gray-300 hover:text-[#3dcffe] transition"
                        >
                            <Nerthbox className="h-3.5" />
                        </Link>
                    </div>

                    <div className="flex items-center gap-2 text-xs text-gray-600 dark:text-gray-300">
                        <span>
                            {" "}
                            <Link
                                className="hover:opacity-80 hover:underline transition"
                                to={
                                    "https://tro-vestassetmngt.com/terms-and-conditions"
                                }
                                target="_blank"
                            >
                                Privacy Policies
                            </Link>{" "}
                            &{" "}
                            <Link
                                className="hover:opacity-80 hover:underline transition"
                                to={
                                    "https://tro-vestassetmngt.com/privacy-policy"
                                }
                                target="_blank"
                            >
                                Terms
                            </Link>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
